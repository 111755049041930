<template>
    <div>
        <div class="r-option-place r-center-flex r-isWrap">
            <InputCustom
                :rightIcon="true"
                :withError="false"
                v-model="request.search" 
                @keyup="setTime()" 
                @keydown="clearTime()" 
                @keydown.tab="setTime()" 
                @keyup.tab="clearTime()" 
                @keypress.enter="getData()" 
                placeholder="Search . . ."
                size='md'
                >
                <template #rightIcon>
                    <div class="y-inputIcon r-center-flex">
                        <img :src="require('@/assets/icons/others/search.svg')" alt="search">
                    </div>
                </template>
            </InputCustom>

            <ButtonCustom
                class="r-ml-auto"
                @click="openModal()"
            >
                Tambah Data
            </ButtonCustom>
        </div>
        
        <TableCustom 
            :columns="columns" 
            :request="request" 
            :fetchData="fetchData"
            :data="data"
            @setSortBy="setSortBy"
            @setShow="setShow" 
            @getData="getData"
            @mouseleave="popup = null"
            @togglePopup="togglePopup"
        > 
            <template #index="props">
                {{curNumber(props.index)}}
            </template>

            <template #updated_at="props">
                {{convertDateTime(props.row.updated_at)}}
            </template>

            <template #updated_by="props">
                {{props.row.updated_by}}
            </template>

            <template #name="props">
                {{props.row.name}}
            </template>
            
            <template #username="props">
                {{props.row.username}}
            </template>
            
            <template #role_name="props">
                {{props.row.role_name}}
            </template>
            
            <!-- <template #hidden_password="props">
                {{ aesDencrypt(props.row.hidden_password) }}
            </template> -->
            
            <template #status="props">
                <ButtonCustom
                    :type="userStatus(props.row.status).type"
                    :disabled="statusLoading != null"
                    @click.stop="updateStatus(props.row)"
                >
                    <img :src="require('@/assets/loadingDot.svg')" alt style="width: 50px;" v-if="statusLoading == props.row.id" />
                    <span v-else>
                        {{userStatus(props.row.status).label}}
                    </span>
                </ButtonCustom>
            </template>

            <template #action="props">
                <div class="r-menu">
                    <img class="r-is-clickable" :src="require('@/assets/icons/others/more.svg')" @click.prevent="popup = curNumber(props.index)">
                    <div class="r-menu-items" v-if="popup == curNumber(props.index)" @mouseleave="popup = -1">
                        <div class="r-menu-item y-black-text r-center-flex" @click.prevent="setData(props.row)">Ubah</div>
                        <div class="r-menu-item y-red-text r-center-flex"  @click.prevent="openDeleteModal(props.row)">Hapus</div>
                    </div>
                </div>
            </template>
        </TableCustom>

        <InputModal
            v-if="modal == 'inputModal'"
            :inputType="inputType"
            @resetData="resetData"
            @getData="getData"
        >
        
        </InputModal>

        <DeleteModal
            v-if="modal == 'deleteModal'"
            @resetData="resetData"
            @getData="getData"
        >
        
        </DeleteModal>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import common from '@/mixins/function/common'
import tableMixins from '@/mixins/tableMixins'
import InputModal from '@/components/Pengguna/InputModal'
import DeleteModal from '@/components/Pengguna/DeleteModal'
// import CryptoJS from 'crypto-js'
export default {
    mixins: [
        common, 
        tableMixins
    ],
    components: {
        InputModal,
        DeleteModal
    },
    mounted() {
        this.initData();
    },
    computed: {
        ...mapGetters ({
            data: 'pengguna/getData',
            request: 'pengguna/getRequest',
            userStatus: 'pengguna/getUserStatus',
        }),

    },
    data: () => ({
        columns: [
            {
                label: 'No',
                isSortable: false,
                fieldName: 'index',
                class: 'r-table-firstColumn'
            },
            {
                label: 'Diupdate Pada',
                isSortable: true,
                fieldName: 'updated_at',
                class: ''
            },
            {
                label: 'Diupdate Oleh',
                isSortable: true,
                fieldName: 'updated_by',
                class: ''
            },
            {
                label: 'Nama',
                isSortable: false,
                fieldName: 'name',
                class: ''
            },
            {
                label: 'Username',
                isSortable: false,
                fieldName: 'username',
                class: ''
            },
            {
                label: 'Role',
                isSortable: false,
                fieldName: 'role_name',
                class: ''
            },
            // {
            //     label: 'Password',
            //     isSortable: false,
            //     fieldName: 'hidden_password',
            //     class: ''
            // },
            {
                label: 'Status',
                isSortable: false,
                fieldName: 'status',
                class: ''
            },
            {
                label: 'Action',
                isSortable: false,
                fieldName: 'action',
                class: 'r-table-actionColumn'
            }
        ],
        modal: null,
        inputType: 'Tambah',
        statusLoading: null,
    }),
    methods: {
        ...mapActions ({
            resetItem: 'pengguna/resetItem',
            resetRequest: 'pengguna/resetRequest',
            setItem: 'pengguna/setItem',
            updateStatusUser: 'pengguna/updateStatus',
            getUsers: 'pengguna/fetchData',
        }),
        openModal(type = 'Tambah') {
            this.modal = 'inputModal'
            this.inputType = type
        },
        setData(item) {
            this.setItem(item)
            this.openModal('Ubah')
        },
        openDeleteModal(item) {
            this.setItem(item)
            this.modal = 'deleteModal'
        },
        resetData() {
            this.modal = null
            this.statusLoading = null
            this.resetItem()
            this.resetRequest()
        },
        async getData(type = 0) {
            this.fetchData = true
            if (type === 0) {
                this.request.page = 1
            } else {
                this.request.page += type
            }
            await this.getUsers()
            this.fetchData = false
            const response = this.getResponse('pengguna')
            if (response.status !==1) {
                this.showSnackbar ({
                    type: 'error',
                    text: response.msg
                })
            }
        },
        async updateStatus(item) {
            this.statusLoading = item.id
            this.loading = true
            await this.updateStatusUser(item)
            this.loading = false
            const response = this.getResponse('pengguna')
            this.showSnackbar({
                type: response.status == 1 ? 'success': 'error',
                text: response.msg
            })
            this.resetData()
            this.getData()
        },
        async initData() {
            const breadcrumb = [{
                name: 'Pengguna',
                link: {name: null},
                current: true
            }]
            this.setActiveTab('Pengguna')
            this.setBreadCrumb(breadcrumb)
            await this.getData()
        },

        // aesDencrypt(txt) {
        //     const iv = '+wl1ff2sKma5ED2DVFl1KA=='
        //     const key = 'tDpkpHyCyISyGZrays3Jrb63pIIN0ZY4p1TClqBc+1E='
        //     const cipher = CryptoJS.AES.decrypt(txt, CryptoJS.enc.Utf8.parse(key), {
        //         iv: CryptoJS.enc.Utf8.parse(iv),
        //         mode: CryptoJS.mode.CBC
        //     })

        //     return CryptoJS.enc.Utf8.stringify(cipher).toString()
        // },

    }
}
</script>
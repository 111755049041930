<template>
    <Modal label="Data Pengguna">
        <InputCustom
            v-model="item.name"
            :error="error.name"
            :errorMsg="errorMsg.name"
            @onFocus="resetError('name')"
            placeholder="Nama"
            label="Nama Pengguna"
            v-if="inputType == 'Tambah'"
        >
        
        </InputCustom>

        <InputCustom
            v-model="item.username"
            :error="error.username"
            :errorMsg="errorMsg.username"
            @onFocus="resetError('username')"
            placeholder="Username"
            label="Username Pengguna"
            v-if="inputType == 'Tambah'"
        >
        
        </InputCustom>

        <InputCustom
            v-model="item.name"
            :error="error.name"
            :errorMsg="errorMsg.name"
            @onFocus="resetError('name')"
            placeholder="Nama"
            label="Nama Pengguna"
            v-if="inputType != 'Tambah'"
            readonly
        >
        
        </InputCustom>

        <InputCustom
            v-model="item.username"
            :error="error.username"
            :errorMsg="errorMsg.username"
            @onFocus="resetError('username')"
            placeholder="Username"
            label="Username Pengguna"
            v-if="inputType != 'Tambah'"
            readonly
        >
        
        </InputCustom>

        <div class="y-inputField">
            <div class="y-labelText">
                Role Pengguna
            </div>
            <div class="y-inputPlace y-hasIcon-right">
                <div class="y-select-hasIcon" style="width: 100%">
                    <select class="y-select" v-model="item.role_id">
                        <option class="y-option"
                        v-for="(r,i) in roles"
                        :key="`list-data-${i}`"
                        :value="r.id"
                        >{{r.name}}</option>
                    </select>
                    <div class="y-select-icon">
                        <img :src="require('@/assets/icons/others/arrowDown.svg')" alt="">
                    </div>
                </div>
            </div>
            <div class="y-errorText y-red-text" :style="error.role_id ? 'opacity: 1 !important;': ''">
                {{errorMsg.role_id}}
            </div>
        </div>

        <template v-if="inputType == 'Tambah'">
            <InputCustom
                :rightIcon="true"
                :type="showpassword == 'pass' ? 'text' : 'password'"
                v-model="item.password"
                :error="error.password"
                :errorMsg="errorMsg.password"
                @onFocus="resetError('password')"
                placeholder="Password"
                label="Password Pengguna"
            >
                <template #rightIcon>
                    <div class="y-inputIcon r-center-flex">
                        <img :src="require(`@/assets/icons/login/${showpassword == 'pass' ? 'eyeClose' : 'eyeOpen'}.svg`)" alt="" @click="setShowPassword('pass')">    
                    </div>
                </template>
            </InputCustom>

            <InputCustom
                :rightIcon="true"
                :type="showpassword == 'confirm' ? 'text' : 'password'"
                v-model="item.confirm_password"
                :error="error.confirm_password"
                :errorMsg="errorMsg.confirm_password"
                @onFocus="resetError('confirm_password')"
                placeholder="Konfirmasi Password"
                label="Konfirmasi Password Pengguna"
            >
                <template #rightIcon>
                    <div class="y-inputIcon r-center-flex">
                        <img :src="require(`@/assets/icons/login/${showpassword == 'confirm' ? 'eyeClose' : 'eyeOpen'}.svg`)" alt="" @click="setShowPassword('confirm')">    
                    </div>
                </template>
            </InputCustom>
        </template>

        <template v-else>
            <div class="y-inputField">
                <div class="y-labelText">
                    Ubah Password
                </div>
                <div class="r-center-flex">
                    <div class="r-center-flex" style="margin-right: 15px">
                        <img 
                            @click="change_password = change_password == true ? false : true" 
                            :src="require(`@/assets/icons/checkmark/${change_password == true ? 'Active' : 'Inactive'}.svg`)" 
                            style="margin-right: 8px" >
                    </div>
                </div>
            </div>
            <template v-if="change_password == true">
                <InputCustom
                    :rightIcon="true"
                    :type="showpassword == 'old' ? 'text' : 'password'"
                    v-model="item.old_password"
                    :error="error.old_password"
                    :errorMsg="errorMsg.old_password"
                    @onFocus="resetError('old_password')"
                    placeholder="Password Lama"
                    label="Password Lama Pengguna"
                >
                    <template #rightIcon>
                        <div class="y-inputIcon r-center-flex">
                            <img :src="require(`@/assets/icons/login/${showpassword == 'old' ? 'eyeClose' : 'eyeOpen'}.svg`)" alt="" @click="setShowPassword('old')">    
                        </div>
                    </template>
                </InputCustom>

                <InputCustom
                    :rightIcon="true"
                    :type="showpassword == 'new' ? 'text' : 'password'"
                    v-model="item.new_password"
                    :error="error.new_password"
                    :errorMsg="errorMsg.new_password"
                    @onFocus="resetError('new_password')"
                    placeholder="Password Baru"
                    label="Password Baru Pengguna"
                >
                    <template #rightIcon>
                        <div class="y-inputIcon r-center-flex">
                            <img :src="require(`@/assets/icons/login/${showpassword == 'new' ? 'eyeClose' : 'eyeOpen'}.svg`)" alt="" @click="setShowPassword('new')">    
                        </div>
                    </template>
                </InputCustom>

                <InputCustom
                    :rightIcon="true"
                    :type="showpassword == 'confirm' ? 'text' : 'password'"
                    v-model="item.confirm_password"
                    :error="error.confirm_password"
                    :errorMsg="errorMsg.confirm_password"
                    @onFocus="resetError('confirm_password')"
                    placeholder="Konfirmasi Password"
                    label="Konfirmasi Password Pengguna"
                >
                    <template #rightIcon>
                        <div class="y-inputIcon r-center-flex">
                            <img :src="require(`@/assets/icons/login/${showpassword == 'confirm' ? 'eyeClose' : 'eyeOpen'}.svg`)" alt="" @click="setShowPassword('confirm')">    
                        </div>
                    </template>
                </InputCustom>
            </template>
        </template>
        
        <template #actionButton>
            <ButtonCustom
                :disabled="loading"
                type="secondary"
                size="sm"
                class="r-mlr-10"
                @click.prevent="resetData()"
            >
                Batal
            </ButtonCustom>
            
            <ButtonCustom
                :disabled="loading"
                type="primary"
                size="sm"
                class="r-mlr-10"
                @click.prevent="sendData()"
            >
                {{inputType}}
            </ButtonCustom>
        </template>
    </Modal>
</template>
<script>
import common from '@/mixins/function/common'
import { mapGetters, mapActions } from 'vuex'
import validation from '@/mixins/function/validation'
import Modal from '@/components/Helper/Modal'
export default {
    mixins: [
        common,
        validation,
    ],
    components: {
        Modal,
    },
    mounted() {
        this.loadRoles = true
        this.getRoles()
    },
    props: {
        inputType: {
            require: true,
        }
    },
    computed: {
        ...mapGetters({
            roles: 'userRole/getRoles',
            item: 'pengguna/getItem'
        })
    },
    data: () => ({
        loadRoles: false,
        error: {
            name: null,
            username: null,
            password: null,
            old_password: null,
            new_password: null,
            confirm_password: null,
            role_id: null,
        },
        errorMsg: {
            name: 'Tidak boleh kosong',
            username: 'Tidak boleh kosong',
            password: 'Tidak boleh kosong',
            old_password: "Tidak Boleh Kosong",
            new_password: "Tidak Boleh Kosong",
            confirm_password: 'Tidak boleh kosong',
            role_id: 'Tidak boleh kosong',
        },
        change_password: false,
        loading: false,
        showpassword: false
    }),
    methods: {
        ...mapActions({
            getAllRole: 'userRole/getAllRole',
            store: 'pengguna/store',
            update: 'auth/update',
        }),
        resetData() {
            this.$emit('resetData')
        },
        // setTime() {
        //     this.typingStatus = true
        //     this.passwordTping = true
        //     clearTimeout(this.typingTimer);
        //     this.typingTimer = setTimeout(this.validatePassword(), 2000);
        // },
        // clearTime() {
        //     clearTimeout(this.typingTimer);
        // },
        setShowPassword(value){
            if(this.showpassword == value){
                this.showpassword = null
            }else{
                this.showpassword = value
            }
        },
        async getRoles() {
            await this.getAllRole()
            const response = this.getResponse('userRole')
            this.loadRoles = false
            if (response.status !== 1) {
                this.showSnackbar({
                    type: 'error',
                    text: response.msg
                })
            }
        },
        async validation() {
            var field = ['name', 'username', 'role_id'];
            if (this.change_password == true) {
                field.push('old_password')
                field.push('new_password')
                field.push('confirm_password')
            }
            if (this.inputType == 'Tambah') {
                field.push('password', 'confirm_password')
            }
            return await this.validate(field, this.item)
        },
        // validatePassword(){ 
        //     this.passwordTyping = false
        //     if (!/^(?=.*[a-z])/.test(this.item.new_password)) {
        //         this.error.new_password_lower = true
        //         this.errorMsg.new_password_lower = "Password harus memiliki huruf kecil"
        //     } else {
        //         this.error.new_password_lower = false
        //     }
        //     if (!/^(?=.*[A-Z])/.test(this.item.new_password)) {
        //         this.error.new_password_upper = true
        //         this.errorMsg.new_password_upper = "Password harus memiliki huruf besar"
        //     } else {
        //         this.error.new_password_upper = false
        //     }
        //     if (!/^[0-9a-zA-Z]{6,}$/.test(this.item.new_password)) {
        //         this.error.new_password_length = true
        //         this.errorMsg.new_password_length = "Password minimal 6 karakter"
        //     } else {
        //         this.error.new_password_length = false
        //     }
        // },
        async sendData() {
            const errorCount = await this.validation()
            if (errorCount === 0) {
                if (this.change_password == true) {
                    if (this.item.confirm_password != this.item.new_password) {
                        this.error.new_password = true
                        this.errorMsg.new_password = "Password tidak sama"
                        this.errorMsg.confirm_password = "Password tidak sama"
                        this.error.confirm_password = true
                        return
                    }
                    if (this.item.old_password == this.item.new_password && this.item.old_password != null && this.item.new_password != null) {
                        this.error.new_password = true
                        this.errorMsg.new_password = "Password tidak boleh sama"
                        this.errorMsg.old_password = "Password tidak boleh sama"
                        this.error.old_password = true
                        return
                    }
                }
                else {
                    if (this.item.password != this.item.confirm_password) {
                        this.error.password = true
                        this.error.confirm_password = true
                        this.errorMsg.password = "Password tidak sama"
                        this.errorMsg.confirm_password = "Password tidak sama"
                        return
                    }
                }
                
                this.loading = true
                if (this.inputType == 'Tambah') {
                    await this.store(this.item)
                } else {
                    await this.update(this.item)
                }
                this.loading = false
                
                const response = this.getResponse('pengguna')
                this.showSnackbar({
                    type: response.status == 1 ? 'success' : 'error',
                    text: response.msg
                })

                if (response.status === 1) {
                    this.resetData()
                    this.$emit('getData')
                }
            } else {
                this.showSnackbar ({
                    type: 'error',
                    text: 'Mohon periksa kembali'
                })
            }
        }
    }
}
</script>